// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---theme-src-templates-home-js": () => import("./../theme/src/templates/home.js" /* webpackChunkName: "component---theme-src-templates-home-js" */),
  "component---theme-src-templates-contact-js": () => import("./../theme/src/templates/contact.js" /* webpackChunkName: "component---theme-src-templates-contact-js" */),
  "component---theme-src-templates-page-js": () => import("./../theme/src/templates/page.js" /* webpackChunkName: "component---theme-src-templates-page-js" */),
  "component---theme-src-pages-404-js": () => import("./../theme/src/pages/404.js" /* webpackChunkName: "component---theme-src-pages-404-js" */)
}

