import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useEffect } from "react";

// the translations
// (TODO: move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      sknAdj: "Saint Kitts & Nevis",
      drAdj: "Dominican",
      turkeyAdj: "Turkish",
      contactFormTitle: "Apply for your {{country}} passport",
      fullName: "Full Name",
      fullNamePlaceholder: "",
      email: "Email",
      emailPlaceholder: "your@email.com",
      wechat: "Wechat (optional)",
      wechatPlaceholder: "Wechat ID",
      phoneNumber: "Telephone number (optional)",
      phoneNumberPlaceholder: "+86 ....",
      phoneNumberHelperText: "Include country code.",
      message: "Message (optional)",
      messagePlaceholder: "If you have any questions, feel free to ask.",
      contactErrorMessage:
        "There was an error when submitting the form. Please try again.",
      contactIntroMessage:
        "Please enter your contact information and one of our representatives will contact you as soon as possible with more infomation.",
      contactSuccessMessage:
        "Thanks for contacting us {{name}}. We will reach out as soon as possible.",
      send: "Send",
      allSites: "All Sites",
    },
  },
  zh: {
    translation: {
      turkeyAdj: "土耳其语",
      contactFormTitle: "免费咨询",
      contactIntroMessage: "",
      contactSuccessMessage: "谢谢。 我们会尽快回复。",
      contactErrorMessage: "表格有误。 请发送电子邮件至info@waihuzhao.com。",
      fullName: "姓名",
      fullNamePlaceholder: "",
      email: "电子邮件",
      emailPlaceholder: "you@email.com",
      wechat: "微信（可选）",
      wechatPlaceholder: "微信ID",
      phoneNumber: "电话号码（可选）",
      phoneNumberPlaceholder: "+86 ....",
      phoneNumberHelperText: "包括国家/地区代码。",
      message: "消息（可选）",
      messagePlaceholder: "如果您有任何问题，请随时提问。",
      send: "发送",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

export function useSetupI18n(subsite) {
  useEffect(() => {
    i18n.changeLanguage(subsite.lang);
  }, [subsite.lang]);
}
