import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./style/theme";
import useCrisp from "./hooks/useCrisp";

import "./i18n";

export default props => {
  useCrisp("3160afbe-2840-436d-8c0c-88199b213d67");

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
