import { useEffect } from "react";

export default websiteId => {
  if (!websiteId) throw new Error("useCrisp requires a websiteId argument");
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = websiteId;
    var d = document;
    var s = d.createElement("script");

    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  }, [websiteId]);
};
