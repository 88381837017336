import React from "react";

export const RightImg = ({ children }) => {
  const style = {
    width: "35%",
    margin: "0 0 15px 15px",
    float: "right",
    display: "block"
  };
  return <div style={style}>{children}</div>;
};
